import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { DSDashboard } from './DSDashboard/DSDashboard';
import DSOrganizationSettings from './DSOrganizationSettings/DSOrganizationSettings';
import { DSProfile } from './DSProfile/DSProfile';

export const DecisionSiteEntryContent = () => {
  const location = useLocation();

  // Determine which navigation tab is currently active based on URL
  const activeTab = useMemo(() => {
    if (
      matchPath(`/organization/:organizationSlug/template`, location?.pathname)
    ) {
      return 'template';
    }

    if (
      matchPath(`/organization/:organizationSlug/settings`, location?.pathname)
    ) {
      return 'settings';
    }

    if (matchPath(`/organization/:organizationSlug/profile`, location?.pathname)) {
      return 'profile';
    }

    return 'dashboard';
  }, [location.pathname]);

  if (activeTab === 'dashboard') {
    return <DSDashboard />;
  }

  if (activeTab === 'settings') {
    return <DSOrganizationSettings />
  }

  if (activeTab === 'profile') {
    return <DSProfile />
  }

  if (activeTab === 'template') {
    return <div>Template</div>;
  }

  return null;
};
