// Navigation bar component for the Decision Site Entry view
// Displays the Augment logo, navigation tabs, and user avatar
import { mergeStyleSets, PrimaryButton } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { AugmentLogo } from '../Components/AugmentLogo';
import { useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useNavigate } from '../../../Hooks/useNavigate';
import classNames from 'classnames';
import DSCurrentUserAvatarMenu from '../DS/DSCurrentUserAvatarMenu';

export const DecisionSiteEntryNavbar = () => {
  const location = useLocation();
  const { organizationSlug } = useParams();
  const navigate = useNavigate();
  const { role } = useOrganization();

  // Determine which navigation tab is currently active based on URL
  const activeTab = useMemo(() => {
    if (
      matchPath(`/organization/:organizationSlug/template`, location.pathname)
    ) {
      return 'template';
    }

    if (
      matchPath(`/organization/:organizationSlug/settings`, location.pathname)
    ) {
      return 'settings';
    }

    if (matchPath(`/organization/:organizationSlug/profile`, location.pathname)) {
      return 'profile';
    }

    return 'dashboard';
  }, [location.pathname]);

  // Styles for the navbar components using FluentUI's mergeStyleSets
  const styles = mergeStyleSets({
    navbar: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.75rem 2rem',
      backgroundColor: DEALROOMS_COLORS.white,
      gap: '2rem',
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
      marginLeft: 'auto',
    },

    navButtons: {
      display: 'flex',
      alignItems: 'center',
      gap: '2rem',
    },

    button: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',

      color: DEALROOMS_COLORS.themePrimary,
      '.ms-Button-label': {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        fontWeight: '400',
        margin: 0,
      },
      ':hover': {
        backgroundColor: 'transparent',
        border: 'none',
        color: DEALROOMS_COLORS.themePrimary,
        textDecoration: 'none',
        '::before': {
          content: '""',
          position: 'absolute',
          bottom: '-4px',
          left: 0,
          width: '100%',
          height: '2px',
          backgroundColor: DEALROOMS_COLORS.themePrimary,
        },
      },
      ':focus': {
        backgroundColor: 'transparent',
        border: 'none',
        color: DEALROOMS_COLORS.themePrimary,
      },
      ':focus-visible': {
        backgroundColor: 'transparent',
        border: 'none',
        color: DEALROOMS_COLORS.themePrimary,
        outline: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
      },
      ':active': {
        backgroundColor: 'transparent',
        border: 'none',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    activeButton: {
      '.ms-Button-label': {
        fontWeight: '600',
      },
      '::before': {
        content: '""',
        position: 'absolute',
        bottom: '-4px',
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: DEALROOMS_COLORS.themePrimary,
      },
    },
  });

  return (
    <div className={styles.navbar}>
      {/* Augment logo on the left */}
      <AugmentLogo />

      {/* Navigation tabs in the center */}
      <div className={styles.navButtons}>
        <PrimaryButton
          className={classNames(styles.button, {
            [styles.activeButton]: activeTab === 'dashboard',
          })}
          onClick={() => {
            navigate(`/organization/${organizationSlug}`);
          }}
        >
          Dashboard
        </PrimaryButton>

        {role === 'ADMIN' && (
          <PrimaryButton
            className={classNames(styles.button, {
              [styles.activeButton]: activeTab === 'settings',
            })}
            onClick={() => {
              navigate(`/organization/${organizationSlug}/settings`);
            }}
          >
            My Organization
          </PrimaryButton>
        )}

        <PrimaryButton
          className={classNames(styles.button, {
            [styles.activeButton]: activeTab === 'profile',
          })}
          onClick={() => {
            navigate(`/organization/${organizationSlug}/profile`);
          }}
        >
          My Profile
        </PrimaryButton>
      </div>

      {/* User avatar on the right */}
      <div className={styles.logoContainer}>
        {/* User avatar image */}
        <DSCurrentUserAvatarMenu />
      </div>
    </div>
  );
};
