// DashboardContent component displays a list of decision sites in a table format
// It includes a header with a title and a button to create new decision sites
import { Text, Image, Stack } from '@fluentui/react';

import DealRoomAsyncCommandBarButton from '../../Components/DealRoomButton';
import { AddToIconProps } from '../../../../utils/iconProps';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useQuery } from 'react-query';
import { OrganizationDealRoomsQuery } from '../../../../QueryNames';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useCallback, useMemo } from 'react';
import { useNavigate } from '../../../../Hooks/useNavigate';
import { DecisionSiteTableList } from '../Components/DecisionSiteTableList';
import { detailsHeader } from '../Components/DecisionSiteTableList.style';
import { useNewDealRoomDialog } from '../../../../Hooks/Modals/DealRoom/useNewDealRoomDialog';
import { useUserProfile } from '../../../../Hooks/useProfile';
import useDeviceType from '../../../../Hooks/deviceDetection';
import { ICellRendererParams } from 'ag-grid-community';
import {
  humanizeDateTime,
  toDateOrUndefined,
} from '@meetingflow/common/DateHelpers';
import { styles } from '../styles';
import { useConfirmationDialog } from '../../../../Hooks/Modals/useConfirmationDialog';
import { DSDashboardDeleteButton } from './DSDashboardDeleteButton';
import { DSDashboardCloneButton } from './DSDashboardCloneButton';
import { DSTabs, DSTab } from '../../DS';
import { Box } from '@mui/material';
import {
  Person as PersonIcon,
  People as PeopleIcon,
} from '@mui/icons-material';

export const DSDashboard = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  // Auth and organization context
  const { getAccessTokenSilently } = useAuth0();
  const { slug, role, name } = useOrganization();
  const { user } = useUserProfile();

  const canManageOrganization = useMemo(() => {
    return role === 'ADMIN' || role === 'CREATOR';
  }, [role]);

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: 'Delete Decision Site?',
    subText:
      'Deleting this Decision Site will delete it for all users and cannot be undone. Are you sure you want to delete this Decision Site?',
    primaryAction: 'CANCEL',
  });

  // Fetch deal rooms data for the organization
  const { data: dealRoomsData, refetch: refetchDealRoomsData } = useQuery(
    OrganizationDealRoomsQuery(slug!),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRooms(
        {
          organizationSlug: slug!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  // Dialog hooks
  const {
    createDeferred: createNewDealRoomDeferred,
    dialog: newDealRoomDialog,
  } = useNewDealRoomDialog({
    organizationSlug: slug!,
    refetchDealRoomsData: refetchDealRoomsData,
  });

  // Define table columns configuration
  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        headerClass: detailsHeader,
        minWidth: 300,
        flex: 1,
        unSortIcon: true,
        cellStyle: {
          display: 'block',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          lineHeight: '64px',
        },
        cellRenderer: (props: ICellRendererParams) => {
          if (!props || !('value' in props)) {
            return '';
          }
          return (
            <div className={styles.nameCell}>
              {props.data.buyerLogo && (
                <Image
                  src={props.data.buyerLogo}
                  width={24}
                  height={24}
                  style={{ borderRadius: '10%' }}
                />
              )}
              <span>{props.value}</span>
            </div>
          );
        },
        comparator: (valueA: string, valueB: string) => {
          if (valueA === valueB) return 0; // Same names, return 0
          if (valueA == null) return -1; // If valueA is null, it goes first
          if (valueB == null) return 1; // If valueB is null, it goes first
          // Case-insensitive comparison
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      ...(!isMobile
        ? [
          {
            field: 'owner',
            headerName: 'Owner',
            headerClass: detailsHeader,
            width: 120,
            flex: 0,
            unSortIcon: true,
            cellStyle: {
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              lineHeight: '64px',
            },
            comparator: (valueA: string, valueB: string) => {
              if (valueA === valueB) return 0; // Same names, return 0
              if (valueA == null) return -1; // If valueA is null, it goes first
              if (valueB == null) return 1; // If valueB is null, it goes first
              // Case-insensitive comparison
              return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            },
            valueFormatter: (props: object) => {
              if (!props || !('value' in props)) {
                return '';
              }
              return props.value as string;
            },
          },
          {
            field: 'lastModified',
            headerName: 'Last Modified',
            headerClass: detailsHeader,
            width: 200,
            flex: 0,
            unSortIcon: true,
            comparator: (valueA: string, valueB: string) => {
              const dateA = toDateOrUndefined(valueA);
              const dateB = toDateOrUndefined(valueB);
              if (dateA === dateB) return 0; // Same names, return 0
              if (dateA == null) return -1; // If valueA is null, it goes first
              if (dateB == null) return 1; // If valueB is null, it goes first
              // Case-insensitive comparison
              return dateA!.getTime() - dateB!.getTime();
            },
            valueFormatter: (props: object) => {
              if (!props || !('value' in props)) {
                return '';
              }

              return humanizeDateTime(props.value as string, {
                displayComponents: ['date'],
                useRelativeDates: false,
              });
            },
          },
          {
            field: 'trash',
            headerName: '',
            headerClass: detailsHeader,
            width: 50,
            flex: 0,
            cellRenderer: (params: ICellRendererParams) => {
              if (!params.data.ownedByCurrentUser) {
                return null;
              }
              const dealRoomId = params.data.key as number;
              return (
                <DSDashboardDeleteButton
                  dealRoomId={dealRoomId}
                  slug={slug!}
                  refetchDealRoomsData={refetchDealRoomsData}
                  createConfirmDeleteDeferred={createConfirmDeleteDeferred}
                />
              );
            },
          },
          {
            field: 'clone',
            headerName: '',
            headerClass: detailsHeader,
            width: 50,
            flex: 0,
            cellRenderer: (params: ICellRendererParams) => {
              const dealRoomId = params.data.key as number;
              return (
                <DSDashboardCloneButton
                  dealRoomId={dealRoomId}
                  slug={slug!}
                  refetchDealRoomsData={refetchDealRoomsData}
                />
              );
            },
          },
        ]
        : []),
    ],
    [isMobile, createConfirmDeleteDeferred, slug, refetchDealRoomsData],
  );

  // Transform API data into table items format
  const listItems = useMemo(() => {
    if (!dealRoomsData) return [];
    if (!user) return [];

    if (!('data' in dealRoomsData)) return [];
    if (!Array.isArray(dealRoomsData.data)) return [];

    const items = dealRoomsData.data.map((dealroom) => ({
      key: dealroom.id,
      buyerLogo: dealroom.buyerLogoUrl,
      lastModified: dealroom.updatedAt,
      ownedByCurrentUser: dealroom.owner?.id === user.id,
      // sharedWithCurrentUser = user is in contacts for deal room but is NOT owner
      sharedWithCurrentUser:
        dealroom.contacts.some((c) => c.userId == user.id) &&
        !(dealroom.owner?.id === user.id),
      name: dealroom.name,
      owner: dealroom.owner?.name || dealroom.owner?.firstName || '',
      description: dealroom.description,
    }));

    if (canManageOrganization) {
      return items;
    } else {
      return items.filter((item) => item.sharedWithCurrentUser);
    }
  }, [dealRoomsData, user, canManageOrganization]);

  // click navigation to each row
  const onRowClicked = useCallback(
    (props: object) => {
      if (props && 'data' in props) {
        // @ts-ignore
        navigate(`/organization/${slug}/decisionsite/${props.data.key}`);
      }
    },
    [navigate, slug],
  );

  const handleCreateNewDealRoom = async () => {
    await createNewDealRoomDeferred().promise;
  };

  const myDecisionSites = listItems.filter((item) => item.ownedByCurrentUser);
  const sharedDecisionSites = listItems.filter(
    (item) => item.sharedWithCurrentUser,
  );

  const panelStyles = useMemo(
    () => ({
      backgroundColor: DEALROOMS_COLORS.white,
      marginTop: '1rem',
      padding: '0',
      borderRadius: '0.25rem',
    }),
    [],
  );

  return (
    <Stack className={styles.container}>
      {confirmDeleteDialog}
      {/* Header section with title and new decision site button */}
      <div className={styles.header}>
        <Stack className={styles.headerTitleContainer}>
          <Text className={styles.title}>Decision Sites</Text>
          <Text className={styles.subtitle}>{name}</Text>
        </Stack>
        {canManageOrganization && (
          <DealRoomAsyncCommandBarButton
            onClick={handleCreateNewDealRoom}
            text="New Decision Site"
            customClasses={styles.addNewButton}
            buttonStyleType="DEAL_ROOM"
            iconProps={AddToIconProps}
          />
        )}
      </div>
      {/* Content section with the table list of decision sites */}
      <div className={styles.content}>
        <DSTabs defaultValue={canManageOrganization ? 'my' : 'shared'} className={styles.tabsWrapper}>
          {canManageOrganization && (
            <DSTab
              value="my"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PersonIcon fontSize="small" />
                  My Decision Sites
                </Box>
              }
              count={myDecisionSites.length}
              panelStyles={panelStyles}
              panel={
                <DecisionSiteTableList
                  items={myDecisionSites}
                  columns={columns}
                  onRowClicked={onRowClicked}
                />
              }
            />
          )}
          <DSTab
            value="shared"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PeopleIcon fontSize="small" />
                Shared with Me
              </Box>
            }
            count={sharedDecisionSites.length}
            panelStyles={panelStyles}
            panel={
              <DecisionSiteTableList
                items={sharedDecisionSites}
                columns={columns}
                onRowClicked={onRowClicked}
              />
            }
          />
          {canManageOrganization && (
            <DSTab
              value="all"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PeopleIcon fontSize="small" />
                  All Decision Sites
                </Box>
              }
              count={listItems.length}
              panelStyles={panelStyles}
              panel={
                <DecisionSiteTableList
                  items={listItems}
                  columns={columns}
                  onRowClicked={onRowClicked}
                />
              }
            />
          )}
        </DSTabs>
      </div>
      {newDealRoomDialog}
    </Stack>
  );
};
