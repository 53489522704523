import React, { useCallback, useMemo, useState } from 'react';
import { AvatarProps } from '@mui/material/Avatar';
import DSMenu from './DSMenu';
import { ClickAwayListener } from '@mui/material';
import { useUserProfile } from '../../../Hooks/useProfile';
import DSMenuItem from './DSMenuItem';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useNavigate } from 'react-router';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { Person, WebAsset, Key, Logout, Clear } from '@mui/icons-material';
import { DSUserAvatar } from '../DS';
import { stringToColor } from '@meetingflow/common/StringHelpers';

// Creates MUI avatar props (background color, initials) based on a string name
export const avatarPropsFromString = (
  name: string,
): { sx: { bgcolor: string }; children: string } => {
  const splitName = name.split(' ');
  const firstWord = splitName[0];
  const secondWord = splitName.length > 1 ? splitName[1] : undefined;
  const firstInitial = (firstWord ? firstWord[0] : '') || '';
  const secondInitial = (secondWord ? secondWord[0] : '') || '';

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${firstInitial}${secondInitial}`,
  };
};

interface DSCurrentUserAvatarMenuProps extends AvatarProps {
  branded?: boolean;
}

const DSCurrentUserAvatarMenu: React.FC<DSCurrentUserAvatarMenuProps> = ({
  branded,
  ...props
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug } = useOrganization();
  const { dealRoomId } = useDealRoom();
  const navigate = useNavigate();
  const { user: profileData } = useUserProfile();

  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onShowUserMenu = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault(); // don't navigate
      setAnchorEl(ev.currentTarget);
      setShowUserMenu(true);
    },
    [setShowUserMenu],
  );

  const onHideUserMenu = useCallback(() => {
    setAnchorEl(null);
    setShowUserMenu(false);
  }, [setShowUserMenu]);

  const userMenuItems = useMemo(() => {
    // these items only appear when a user is on a Decision Site and not on the home page
    const decisionSiteItems = [
      <DSMenuItem
        key={'profile'}
        primaryText="Your Profile"
        onClick={() => {
          navigate(
            `/organization/${organizationSlug}/decisionsite/${dealRoomId}/settings?group=profile`,
          );
        }}
        icon={<Person />}
        branded={branded}
      />,
      <DSMenuItem
        key={'sites'}
        primaryText="Your Decision Sites"
        onClick={() => {
          navigate(`/organization/${organizationSlug}`);
        }}
        icon={<WebAsset />}
        branded={branded}
      />,
    ];

    // these items appear regardless of what page the user is on
    const items = [
      import.meta.env.DEV ? (
        <DSMenuItem
          key={'copy-token'}
          primaryText="Copy bearer token"
          onClick={() => {
            getAccessTokenSilently().then((token) => {
              navigator?.clipboard
                ?.writeText(token)
                .then(() => toast(`Bearer token copied to clipboard`));
            });
          }}
          icon={<Key />}
          branded={branded}
        />
      ) : undefined,
      import.meta.env.DEV ? (
        <DSMenuItem
          key={'clear-local-storage'}
          primaryText="Clear Local Storage"
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
          icon={<Clear />}
          branded={branded}
        />
      ) : undefined,
      <DSMenuItem
        key={'sign-out'}
        primaryText="Sign out"
        onClick={() => {
          navigate('/logout');
        }}
        icon={<Logout />}
        branded={branded}
      />,
    ].filter(Truthy);

    if (!dealRoomId) {
      return items;
    }

    return [...decisionSiteItems, ...items];
  }, [navigate, organizationSlug, dealRoomId, branded, getAccessTokenSilently]);

  const userName = profileData?.name || 'User';

  const avatarUrl =
    profileData?.avatarFileUrl || profileData?.avatarUrl || undefined;

  return (
    <ClickAwayListener onClickAway={onHideUserMenu}>
      <>
        <DSUserAvatar
          {...avatarPropsFromString(userName)}
          src={avatarUrl}
          onClick={onShowUserMenu}
          {...props}
        />
        <DSMenu
          anchorEl={anchorEl}
          open={showUserMenu}
          onClose={onHideUserMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {userMenuItems}
        </DSMenu>
      </>
    </ClickAwayListener>
  );
};

export default DSCurrentUserAvatarMenu;
