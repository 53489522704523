import { Text } from '@fluentui/react';
import { styles } from '../styles';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useMemo, useCallback } from 'react';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { DecisionSiteTableList } from '../Components/DecisionSiteTableList';
import { OrganizationInvite } from '@meetingflow/common/Api/data-contracts';
import { ICellRendererParams } from 'ag-grid-community';
import useDeviceType from '../../../../Hooks/deviceDetection';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { OrganizationInvitesQuery } from '../../../../QueryNames';
import StyledDateTime from '../../../StyledDateTime';
import { InvitesApiClient } from '../../../../Services/NetworkCommon';
import { AsyncIconButton } from '../../../HOC/AsyncButton';
import { DeleteIconProps } from '../../../../utils/iconProps';
import toast from 'react-hot-toast';

export const DSOrganizationInvitesTable = () => {
    const { isMobile } = useDeviceType();
    const { getAccessTokenSilently } = useAuth0();
    const organizationSlug = useOrganizationSlug();

    const {
        data: invites,
        refetch: refetchInvites
    } = useQuery(
        OrganizationInvitesQuery(organizationSlug),
        async () => {
            const token = await getAccessTokenSilently();
            return InvitesApiClient.getOrgInvites(
                { organizationSlug: organizationSlug! },
                { headers: { Authorization: `Bearer ${token}` } },
            );
        },
        { enabled: true },
    );

    const deleteInvite = useCallback(async (inviteId: string) => {
        try {
            const token = await getAccessTokenSilently();
            await toast.promise(
                InvitesApiClient.deleteInvite(inviteId, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
                {
                    loading: 'Deleting invite...',
                    success: () => {
                        refetchInvites();
                        return 'Invite deleted successfully';
                    },
                    error: 'Failed to delete invite'
                }
            );
        } catch (err) {
            // Error handled by toast
        }
    }, [getAccessTokenSilently, refetchInvites]);

    const columnDefs = useMemo(
        () => [
            {
                field: 'email',
                headerName: 'Email',
                unSortIcon: true,
                flex: 1
            },
            ...(!isMobile
                ? [
                    {
                        field: 'role',
                        headerName: 'Role',
                        unSortIcon: true,
                    },
                    {
                        field: 'createdAt',
                        headerName: 'Created At',
                        unSortIcon: true,
                        cellRenderer: (props: ICellRendererParams) => {
                            if (!props || !('value' in props)) {
                                return '';
                            }
                            return <StyledDateTime dateTime={props.value as string} useRelativeDates />;
                        },
                    },
                    {
                        field: 'invitedBy',
                        headerName: 'Invited By',
                        unSortIcon: true,
                    },
                    {
                        field: 'delete',
                        headerName: 'Delete',
                        width: 100,
                        cellRenderer: (params: ICellRendererParams) => {
                            return (
                                <AsyncIconButton
                                    name="Delete"
                                    iconProps={DeleteIconProps}
                                    onClick={async (e) => { }}
                                    // onClickCapture is required for stopPropagation to work - otherwise the row gets the click
                                    onClickCapture={async (e) => { 
                                        e.stopPropagation(); 
                                        await deleteInvite(params.data.id);
                                    }}
                                />
                            );
                        }
                    }
                ]
                : []
            ),
        ],
        [isMobile, deleteInvite]
    );

    const invitesTableRows = useMemo(() => {
        return (invites?.data ?? []).map((invite: OrganizationInvite) => {
            return {
                id: invite.id,
                name: invite.invitee.name,
                email: invite.invitee.email,
                role: invite.role,
                createdAt: invite.createdAt,
                invitedBy: invite.inviter.name,
            };
        });
    }, [invites]);

    return (
        <>
            <Text variant="large">Invites</Text>

            <DecisionSiteTableList
                items={invitesTableRows}
                columns={columnDefs}
            />
        </>
    );
};