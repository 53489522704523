import { useCallback, useEffect, useState } from 'react';
import { Stack, Icon } from '@fluentui/react';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import { ImageWithFallback } from '../../../../Common/ImageWithFallback';
import { getDSOrganizationLogoStyles } from './DSOrganizationLogo.styles';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { OrganizationQuery, OrganizationsQuery } from '../../../../../QueryNames';
import { useAuth0 } from '@auth0/auth0-react';
import { pickFile } from '../../../../../Helpers/FileHelpers';
import { OrganizationsApiClient } from '../../../../../Services/NetworkCommon';

export const DSOrganizationLogo = () => {
  const { isMobile } = useDeviceType();
  const styles = getDSOrganizationLogoStyles(isMobile);
  const [isHovered, setIsHovered] = useState(false);
  const { organization, refetch: refetchOrganization } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const { slug: organizationSlug } = useOrganization();

  const handleLogoClick = async () => {
    const file = await pickFile({ fileTypes: 'image' });
    if (!file) {
      return;
    }

    const token = await getAccessTokenSilently();
    // Upload the logo with a toast notification
    await toast.promise(
      OrganizationsApiClient.uploadOrganizationLogo(
        organizationSlug!,
        { file },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
      {
        loading: 'Uploading...',
        success: (r) => {
          refetchOrganization();
          return 'Organization Logo uploaded';
        },
        error: 'Failed to update organization logo',
      },
    );
  };

  return (
    <Stack
      className={styles.logoWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleLogoClick}
    >
      {organization?.logoUrl ? (
        <ImageWithFallback 
          src={organization?.logoUrl}
           className={styles.logo}
           style={{filter: isHovered ? 'brightness(0.3)' : 'none', transition: 'all 0.2s ease-in-out'}} />
      ) : (
        <Stack className={styles.placeholderLogo}>
          <Icon iconName="Image" className={styles.placeholderIcon} />
        </Stack>
      )}
      {isHovered && (
        <Stack className={styles.overlay}>
          <Icon iconName="Edit" className={styles.editIcon} />
        </Stack>
      )}
    </Stack>
  );
};

export default DSOrganizationLogo;
