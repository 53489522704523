import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

// Styles for component layout and appearance
export const styles = mergeStyleSets({
  container: {
    containerType: 'inline-size',
    '.ms-layer:empty': {
      display: 'none',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'last baseline',
    gap: '.5rem',
    '@container (max-width: 540px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  headerTitleContainer: {
    flex: 1,
  },
  title: {
    color: DEALROOMS_COLORS.themePrimary,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    fontWeight: '400',
    flex: 1,
  },
  subtitle: {
    color: DEALROOMS_COLORS.themeSecondary,
    fontSize: '1.25rem',
    lineHeight: '2rem',
    fontWeight: '500',
    flex: 1,
    '@container (max-width: 540px)': {
      wordBreak: 'break-word',
    },
  },
  addNewButton: {
    padding: '.625rem 1rem',
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
  },
  content: {
    paddingTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    height: '100%',
    overflow: 'auto',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    maxWidth: '100%',
    width: '100%',
    span: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      lineHeight: '64px',
      maxWidth: '100%',
      width: '100%',
      display: 'block',
    },
  },
  tabsWrapper: {
    '.MuiTabs-root': {
      marginBottom: '1rem',
    },
    '.MuiBox-root': {
      backgroundColor: 'transparent',
    },
  },
  tabPanel: {
    marginTop: '1rem',
    padding: '0',
  },
});
