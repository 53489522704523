import { mergeStyleSets } from '@fluentui/react';

export const getDSOrganizationMembersStyles = () => {
  return mergeStyleSets({
    membersContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    membersHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  });
};
