import { useAuth0 } from '@auth0/auth0-react';
import { PrimaryButton, Stack } from '@fluentui/react';
import {
  OrganizationInvite,
  OrganizationMemberRole,
} from '@meetingflow/common/Api/data-contracts';
import { getDomain } from '@meetingflow/common/StringHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { GOOGLE_CAL_DOMAINS } from '../../../../Constants';
import { useOrganization } from '../../../../Hooks/useOrganization';
import {
  InvitesQuery,
  OrganizationInvitesQuery,
  OrganizationMemberSuggestionsQuery,
  OrganizationMembersQuery,
} from '../../../../QueryNames';
import { InvitesApiClient } from '../../../../Services/NetworkCommon';
import { DSResponsiveModal } from '../../DS/DSResponsiveModal';
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { ITag } from '@fluentui/react/lib/Pickers';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';

const EmailInputWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  alignItems: 'center',
  padding: '8px 12px',
  minHeight: '32px',
  fontSize: '14px',
  border: '1px solid #EDEBE9',
  borderRadius: '4px',
  backgroundColor: '#FFFFFF',
  '& .MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
    color: '#605E5C',
  }
});

interface DSOrganizationInviteMemberProps {
  onInvite?: (invite: OrganizationInvite) => void;
}

const emailPattern = /^[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/;

export const DSOrganizationInviteMember = ({
  onInvite,
}: DSOrganizationInviteMemberProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const organizationSlug = useOrganizationSlug();
  const appInsights = useAppInsightsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailsToInvite, setEmailsToInvite] = useState<ITag[]>([]);
  const [currentInput, setCurrentInput] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<OrganizationMemberRole>('COLLABORATOR');
  const [isSaving, setIsSaving] = useState(false);

  const handleInvite = async () => {
    if (emailsToInvite.length === 0) {
      toast.error('Please enter at least one email address');
      return;
    }

    setIsSaving(true);
    try {
      const token = await getAccessTokenSilently();

      for (const email of emailsToInvite) {
        const domain = getDomain(email.name);

        if (GOOGLE_CAL_DOMAINS.includes(domain)) {
          toast.error(`Cannot invite ${domain} email addresses`);
          continue;
        }

        const result = await InvitesApiClient.putInvite(
          {
            email: email.name,
            role: selectedRole,
            organizationSlug: organizationSlug!,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (result.status === 201) {
          client.invalidateQueries(InvitesQuery());
          client.invalidateQueries(InvitesQuery('SENT'));
          client.invalidateQueries(OrganizationMembersQuery(organizationSlug!));
          client.invalidateQueries(OrganizationInvitesQuery(organizationSlug!));

          onInvite?.(result.data);
          toast.success(`Invited ${email.name}`);
        }

        appInsights.trackEvent({
          name: 'SEND_INVITE',
          properties: {
            organizationSlug,
            email: email.name,
            role: selectedRole,
          },
        });
      }

      setIsModalOpen(false);
      setEmailsToInvite([]);
      setCurrentInput('');
    } catch (error) {
      console.error('Error inviting member:', error);
      toast.error('Failed to invite member');
    } finally {
      setIsSaving(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
      event.preventDefault();
      const email = currentInput.trim();
      if (email && emailPattern.test(email)) {
        setEmailsToInvite([...emailsToInvite, { key: email, name: email }]);
        setCurrentInput('');
      }
    } else if (event.key === 'Backspace' && !currentInput && emailsToInvite.length > 0) {
      setEmailsToInvite(emailsToInvite.slice(0, -1));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.endsWith(' ') || value.endsWith(',')) {
      const email = value.slice(0, -1).trim();
      if (email && emailPattern.test(email)) {
        setEmailsToInvite([...emailsToInvite, { key: email, name: email }]);
        setCurrentInput('');
      }
    } else {
      setCurrentInput(value);
    }
  };

  const handleEmailRemove = (emailToRemove: ITag) => {
    setEmailsToInvite(emailsToInvite.filter(email => email.key !== emailToRemove.key));
  };

  const modalContent = (
    <Stack tokens={{ childrenGap: 24 }}>
      <EmailInputWrapper>
        {emailsToInvite.map((email) => (
          <Chip
            key={email.key}
            label={email.name}
            onDelete={() => handleEmailRemove(email)}
          />
        ))}
        <input
          style={{
            border: 'none',
            outline: 'none',
            flex: 1,
            minWidth: 100,
            fontSize: 'inherit',
          }}

          value={currentInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter email address"
        />
      </EmailInputWrapper>

      <FormControl fullWidth>
        <InputLabel>Role</InputLabel>
        <Select
          value={selectedRole}
          label="Role"
          onChange={(e) => setSelectedRole(e.target.value as OrganizationMemberRole)}
          size="small"
          sx={{ '.MuiSelect-select': { padding: '8px 14px' } }}
        >
          <MenuItem value={'CREATOR'} sx={{ minHeight: '32px' }}>Creator (Can create Decision Sites) </MenuItem>
          <MenuItem value={'COLLABORATOR'} sx={{ minHeight: '32px' }}>Collaborator (Can interact with Decision Sites)</MenuItem>
          <MenuItem value={'ADMIN'} sx={{ minHeight: '32px' }}>Admin (Can manage organization)</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <PrimaryButton
          onClick={() => setIsModalOpen(true)}
          text="Invite"
          styles={{ root: { width: '100px' } }}
        />
      </div>

      <DSResponsiveModal
        open={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        title="Invite to your organization"
        maxWidth="sm"
        primaryFooterButtons={[
          {
            children: isSaving ? 'Inviting...' : 'Invite',
            onClick: handleInvite,
            disabled: emailsToInvite.length === 0 || isSaving,
          },
        ]}
      >
      {modalContent}
    </DSResponsiveModal >
    </>
  );
};

export default DSOrganizationInviteMember;
