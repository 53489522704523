import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Grid } from '@mui/material';
import { capitalize } from 'lodash';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useUserProfile } from '../../Hooks/useProfile';
import { useTitle } from '../../Hooks/useTitle';
import { ListOrganizationsData } from '@meetingflow/common/Api/data-contracts';
import { OrganizationsApiClient } from '../../Services/NetworkCommon';
import { OrganizationPersona } from '../OrganizationPersona';
import { DecisionSiteHomeContainer } from './DecisionSiteHomeContainer';
import { DecisionSiteHomePlaceholder } from './DecisionSiteHomePlaceholder';
import {
  ContentBox,
  SectionTitle,
  WorkspacesContainer,
  WorkspacePaper,
} from './DecisionSiteHome.styles';

export const DecisionSiteHome = () => {
  const { user: auth0User, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { user } = useUserProfile();

  useTitle('Home');

  // Build welcome message using user's first name from various possible sources
  const headerTitle = useMemo(() => {
    const generalTitle = `Welcome to ${import.meta.env.VITE_SITE_NAME}`;
    const userName =
      user?.firstName ||
      user?.name?.split(' ')[0] ||
      auth0User?.nickname ||
      auth0User?.name?.split(' ')[0];

    return userName ? `${generalTitle}, ${capitalize(userName)}` : generalTitle;
  }, [user, auth0User]);

  // Fetch organizations data
  const [organizations, setOrganizations] = useState<ListOrganizationsData | null>(null);
  const [organizationsAreLoading, setOrganizationsAreLoading] = useState(true);

  const fetchOrgData = useCallback(async () => {
    try {
      setOrganizationsAreLoading(true);
      const token = await getAccessTokenSilently();
      const response = await OrganizationsApiClient.listOrganizations(
        {
          entitlement: 'DEAL_ROOM'
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setOrganizations(response.data);
    } catch (err) {
      console.error('Error fetching organizations:', err);
    } finally {
      setOrganizationsAreLoading(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchOrgData();
  }, [fetchOrgData]);

  // Auto-navigate if user only has one organization
  useEffect(() => {
    if (organizations?.length === 1) {
      navigate(`/organization/${organizations[0].slug}`);
    }
  }, [navigate, organizations]);

  return (
    <DecisionSiteHomeContainer
      title={headerTitle}
      description={`To get started using ${import.meta.env.VITE_SITE_NAME}, select one of your workspaces.`}
    >
      <ContentBox>
        {(() => {
          if (organizationsAreLoading) {
            return <CircularProgress />;
          }

          if (!organizations || !organizations.length) {
            return <DecisionSiteHomePlaceholder />;
          }

          return (
            <>
              <SectionTitle variant="h6">
                Your Workspaces
              </SectionTitle>
              <WorkspacesContainer container>
                {organizations.map((organization) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={organization.id}>
                    <WorkspacePaper onClick={() => navigate(`/organization/${organization.slug}`)}>
                      <OrganizationPersona organization={organization} />
                    </WorkspacePaper>
                  </Grid>
                ))}
              </WorkspacesContainer>
            </>
          );
        })()}
      </ContentBox>
    </DecisionSiteHomeContainer>
  );
};

export default DecisionSiteHome;
