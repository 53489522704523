import { useMemo } from 'react';
import { DSMenuButton } from '../DS';
import DSMenuItem from './DSMenuItem';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { Mail, Phone, Event, AccountCircle } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

const CONTACT_METHODS = {
  EMAIL: 'email',
  PHONE: 'phone',
  CALENDAR: 'calendar',
} as const;

interface DSSellerContactButtonProps {
  branded?: boolean;
}

const DSSellerContactButton = ({ branded }: DSSellerContactButtonProps) => {
  const { dealRoom, isLoading } = useDealRoom();
  const dealRoomOwner =
    dealRoom?.owner?.firstName || dealRoom?.owner?.name || 'Seller';
  const dealRoomOwnerEmail = dealRoom?.owner?.email || '';
  const dealRoomOwnerPhoneNumber = dealRoom?.owner?.phoneNumber || '';
  const dealRoomOwnerSchedulingUrl = dealRoom?.owner?.schedulingUrl || '';

  const sanitizeUrl = useMemo(() => {
    const fn = (url: string): string => {
      return url.startsWith('mailto:') ||
        url.startsWith('tel:') ||
        url.startsWith('https://')
        ? url
        : '';
    };
    return fn;
  }, []);

  const openUrl = useMemo(() => {
    return (url: string): void => {
      const sanitizedUrl = sanitizeUrl(url);
      if (sanitizedUrl) {
        window.open(sanitizedUrl, '_blank');
      }
    };
  }, [sanitizeUrl]);

  const contactMenuItems = useMemo(() => {
    const items = [];

    if (dealRoomOwnerEmail) {
      items.push(
        <DSMenuItem
          key={CONTACT_METHODS.EMAIL}
          onClick={() => {
            openUrl(`mailto:${dealRoomOwnerEmail}`);
          }}
          primaryText={`Email ${dealRoomOwner}`}
          secondaryText={dealRoomOwnerEmail}
          icon={<Mail />}
          branded
        />,
      );
    }

    if (dealRoomOwnerPhoneNumber) {
      items.push(
        <DSMenuItem
          key={CONTACT_METHODS.PHONE}
          onClick={() => {
            openUrl(`tel:${dealRoomOwnerPhoneNumber}`);
          }}
          primaryText={`Call ${dealRoomOwner}`}
          secondaryText={dealRoomOwnerPhoneNumber}
          icon={<Phone />}
          branded
        />,
      );
    }

    if (dealRoomOwnerSchedulingUrl) {
      items.push(
        <DSMenuItem
          key={CONTACT_METHODS.CALENDAR}
          onClick={() => {
            openUrl(dealRoomOwnerSchedulingUrl);
          }}
          primaryText="Schedule a meeting"
          icon={<Event />}
          branded
        />,
      );
    }

    return items;
  }, [
    dealRoomOwner,
    dealRoomOwnerEmail,
    dealRoomOwnerPhoneNumber,
    dealRoomOwnerSchedulingUrl,
    openUrl,
  ]);

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  if (contactMenuItems.length === 0) {
    return null;
  }

  return (
    <DSMenuButton
      label={`Contact ${dealRoomOwner}`}
      startIcon={<AccountCircle />}
      menuItems={contactMenuItems}
      branded={branded}
      className="action-button contact-button"
      size="medium"
    />
  );
};

export default DSSellerContactButton;
