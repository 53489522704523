import { useCallback, useMemo, useState } from 'react';
import {
    Avatar,
    Stack,
    Typography,
} from '@mui/material';
import {
    EditOutlined as EditIcon,
} from '@mui/icons-material';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { useUserProfile } from '../../../../../Hooks/useProfile';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import DSTextField from '../../../DS/DSTextField';
import { pickFile } from '../../../../../Helpers/FileHelpers';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { UserApiClient } from '../../../../../Services/NetworkCommon';
import { StyledAvatarWrapper, StyledOverlay, StyledSection, getDSProfileAccountStyles } from './DSProfileAccount.styles';

export const DSProfileAccount = () => {
    const { isMobile } = useDeviceType();
    const { user, refetch: refetchUser, updateUserProfileAsync } = useUserProfile();
    const { getAccessTokenSilently } = useAuth0();
    const styles = getDSProfileAccountStyles(isMobile);

    const [isAvatarHovered, setIsAvatarHovered] = useState(false);
    const [firstName, setFirstName] = useState(user?.firstName);
    const [lastName, setLastName] = useState(user?.lastName);
    const [schedulingUrl, setSchedulingUrl] = useState(user?.schedulingUrl);
    const [linkedInUrl, setLinkedInUrl] = useState(user?.linkedInUrl);
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
    const [email, setEmail] = useState(user?.email);

    const validateLinkedInUrl = (value: (string | null | undefined)) => {
        if (!value) return false;
        return Boolean(value.match(/^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[\w-]{5,30}\/?$/));
    };

    const validateUrl = (value: (string | null | undefined)) => {
        if (!value) return false;
        return Boolean(value.match(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\w .-]*)*\/?$/));
    };

    const validatePhoneNumber = (value: (string | null | undefined)) => {
        if (!value) return false;
        return Boolean(value.match(/^(\+\d{1,3}[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/));
    };

    const validateName = (value: string | null | undefined) => {
        if (!value?.trim()) return false;
        return true;
    };

    useMemo(() => {
        if (!user) {
            return;
        }

        setFirstName(user.firstName);
        setLastName(user.lastName);
        setSchedulingUrl(user.schedulingUrl);
        setLinkedInUrl(user.linkedInUrl);
        setPhoneNumber(user.phoneNumber);
        setEmail(user.email);
    }, [user]);

    const onBlur = useCallback(async () => {
        if (!user) {
            return;
        }

        const fields = {
            ...(validateName(firstName) && { firstName }), 
            ...(validateName(lastName) && { lastName }),
            ...(validateUrl(schedulingUrl) && { schedulingUrl }),
            ...(validateLinkedInUrl(linkedInUrl) && { linkedInUrl }),
            ...(validatePhoneNumber(phoneNumber) && { phoneNumber })
        };

        const updates = Object.entries(fields).reduce((acc, [key, value]) => {
            if (value !== user[key as keyof typeof user]) {
                acc[key] = value;
            }
            return acc;
        }, {} as Record<string, (string | null)>);

        if (Object.keys(updates).length === 0) {
            return;
        }

        try {
            await updateUserProfileAsync(updates);
        } catch (e) {
            console.error(e);
        }
    }, [firstName, lastName, schedulingUrl, linkedInUrl, phoneNumber, user, updateUserProfileAsync]);

    const uploadAvatar = useCallback(async () => {
        const avatar = await pickFile({ excludeAcceptAll: true, fileTypes: 'image' });

        if (!avatar) {
            toast.error('No file selected');
            return;
        }

        const token = await getAccessTokenSilently();

        try {
            await UserApiClient.uploadAvatar(
                {
                    file: avatar,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                },
            );

            await refetchUser();
        } catch (e) {
            return null;
        }
    }, [getAccessTokenSilently, refetchUser]);

    if (!user) {
        return null;
    }

    return (
        <Stack className={styles.content}>
            <Stack direction="row" alignItems="center" spacing={4}>
                <StyledAvatarWrapper
                    onMouseEnter={() => setIsAvatarHovered(true)}
                    onMouseLeave={() => setIsAvatarHovered(false)}
                    onClick={uploadAvatar}
                >
                    <Avatar
                        src={user.avatarFileUrl || user.avatarUrl || undefined}
                        alt={user.name || ''}
                        sx={{
                            width: 100,
                            height: 100,
                            filter: isAvatarHovered ? 'brightness(0.3)' : 'none',
                            transition: 'all 0.2s ease-in-out'
                        }}
                    />
                    {isAvatarHovered && (
                        <StyledOverlay>
                            <EditIcon
                                sx={{
                                    color: DEALROOMS_COLORS.white,
                                    fontSize: '1.5rem',
                                }}
                            />
                        </StyledOverlay>
                    )}
                </StyledAvatarWrapper>

                <Stack spacing={3} sx={{
                    width: '100%'
                }}>
                    <DSTextField
                        size="small"
                        label="First Name"
                        defaultValue={firstName}
                        onBlur={onBlur}
                        onChange={(e) => setFirstName(e.target.value)}
                        error={!validateName(firstName)}
                        sx={{
                            maxWidth: '400px',
                            backgroundColor: DEALROOMS_COLORS.inputLightGray,
                            '& .MuiFormHelperText-root': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    />
                    <DSTextField
                        size="small"
                        label="Last Name"
                        defaultValue={lastName}
                        onBlur={onBlur}
                        onChange={(e) => setLastName(e.target.value)}
                        error={!validateName(lastName)}
                        sx={{
                            maxWidth: '400px',
                            backgroundColor: DEALROOMS_COLORS.inputLightGray,
                            '& .MuiFormHelperText-root': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    />
                </Stack>
            </Stack>

            <StyledSection>
                <Typography variant="body2" fontWeight={500} sx={{ color: DEALROOMS_COLORS.themePrimary }}>
                    My Information
                </Typography>
                <DSTextField
                    size="small"
                    label="Email"
                    defaultValue={email}
                    disabled
                    sx={{
                        backgroundColor: DEALROOMS_COLORS.inputLightGray
                    }}
                />

                <DSTextField
                    size="small"
                    label="Linkedin"
                    onBlur={onBlur}
                    defaultValue={linkedInUrl}
                    onChange={(e) => setLinkedInUrl(e.target.value)}
                    error={!validateLinkedInUrl(linkedInUrl)}
                    helperText={!validateLinkedInUrl(linkedInUrl) ? 'Please enter a valid LinkedIn profile URL' : ''}
                    sx={{
                        backgroundColor: DEALROOMS_COLORS.inputLightGray
                    }}
                />

                <DSTextField
                    size="small"
                    defaultValue={schedulingUrl}
                    onBlur={onBlur}
                    onChange={(e) => setSchedulingUrl(e.target.value)}
                    label="Scheduling URL"
                    error={!validateUrl(schedulingUrl)}
                    helperText={!validateUrl(schedulingUrl) ? 'Please enter a valid URL' : ''}
                    sx={{
                        backgroundColor: DEALROOMS_COLORS.inputLightGray
                    }}
                />

                <DSTextField
                    size="small"
                    defaultValue={phoneNumber}
                    onBlur={onBlur}
                    label="Phone Number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={!validatePhoneNumber(phoneNumber)}
                    helperText={!validatePhoneNumber(phoneNumber) ? 'Please enter a valid phone number' : ''}
                    sx={{
                        backgroundColor: DEALROOMS_COLORS.inputLightGray
                    }}
                />
            </StyledSection>
        </Stack>
    );
};
