import { AgGridReact } from 'ag-grid-react';
import {
  GridOptions,
  AllCommunityModule,
  ModuleRegistry,
} from 'ag-grid-community';
import useDeviceType from '../../../../Hooks/deviceDetection';

import { MainContainerStyles } from './DecisionSiteTableList.style';

ModuleRegistry.registerModules([AllCommunityModule]);

interface TableListProps<T> {
  items: T[];
  columns: object[];
  onRowClicked?: (props: object) => void;
}

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true, // Disable reordering for all columns
  suppressClickEdit: true, // Disable inline editing
  // suppressCellFocus: true, // Disable cell focus
  // suppressHeaderFocus: true, // Disable header focus
  readOnlyEdit: true, // Disable editing
  enableCellTextSelection: true, // Enable cell text selection
  defaultColDef: {
    resizable: false, // All columns are resizable by default
    sortable: true, // All columns are sortable by default
    filter: false, // All columns can be filtered by default
    flex: 1,
  },
  headerHeight: 48,
  rowHeight: 64,
};

export const DecisionSiteTableList = <T extends object>({
  items,
  columns,
  onRowClicked,
}: TableListProps<T>) => {
  const { isMobile } = useDeviceType();

  const mainContainerStyles = MainContainerStyles(isMobile);

  return (
    <div className={mainContainerStyles}>
      <AgGridReact
        gridOptions={gridOptions}
        rowData={items}
        columnDefs={columns}
        onRowClicked={onRowClicked}
        overlayNoRowsTemplate='No Decision Sites'
      />
    </div>
  );
};
