import { useEffect } from 'react';
import { useMatch, useParams } from 'react-router-dom';

export const DecisionSiteMeetingPlanRedirect = () => {
  const { meetingPlanId } = useParams();
  const match = useMatch({
    path: '/organization/:organizationSlug/decisionsite/:dealRoomId/*',
    end: false,
  });
  
  const organizationSlug = match?.params.organizationSlug;
  const dealRoomId = match?.params.dealRoomId;

  useEffect(() => {
    if (!organizationSlug || !dealRoomId) {
      throw new Error('Missing required parameters for redirect');
    }

    window.location.href = `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingPlanId}`;
  }, [organizationSlug, dealRoomId, meetingPlanId]);

  return null;
};
