import { Box, Typography, styled, Stack } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { mergeStyleSets } from '@fluentui/react';

export const StyledSection = styled(Stack)(({ theme }) => ({
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    borderRadius: '0.25rem',
    backgroundColor: DEALROOMS_COLORS.neutralGray,
    padding: '1rem',
    marginBottom: '1.5rem',
    gap: '1.5rem',
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    color: DEALROOMS_COLORS.themePrimary,
    marginBottom: '1rem',
}));

export const StyledAvatarWrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    minWidth: '100px',
    overflow: 'hidden',
    ':hover': {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }
}));

export const StyledOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease-in-out',
}));

export const getDSProfileAccountStyles = (isMobile?: boolean) => {
  return mergeStyleSets({
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      padding: '1.5rem',
      backgroundColor: DEALROOMS_COLORS.white,
      borderRadius: '.25rem',
      boxShadow: '0px 2px 2px 0px #00000005',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    sectionTitle: {
      color: DEALROOMS_COLORS.themePrimary,
    },
    sectionDescription: {
      color: DEALROOMS_COLORS.themeSecondary,
      fontSize: '0.875rem',
      fontWeight: '400',
      paddingBottom: '0.5rem',
    },
  });
};

