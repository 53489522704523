import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { OrganizationInvitesQuery } from '../../../../../QueryNames';
import { InvitesApiClient } from '../../../../../Services/NetworkCommon';
import DSOrganizationInviteMember from '../DSOrganizationInviteMemberForm';
import { OrganizationInvite } from '@meetingflow/common/Api/data-contracts';
import { DSOrganizationMembersTable } from '../DSOrganizationMembersTable';
import { DSOrganizationInvitesTable } from '../DSOrganizationInvitesTable';
import { getDSOrganizationMembersStyles } from './DSOrganizationMembers.styles';
import { Text } from '@fluentui/react';

export const DSOrganizationMembers = () => {
  const styles = getDSOrganizationMembersStyles();

  const organizationSlug = useOrganizationSlug();
  const { getAccessTokenSilently } = useAuth0();

  const { data: inviteData, refetch: refetchInvites } = useQuery(
    OrganizationInvitesQuery(organizationSlug),
    async () => {
      const token = await getAccessTokenSilently();
      return InvitesApiClient.getOrgInvites(
        { organizationSlug: organizationSlug! },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    },
    { enabled: true },
  );

  return (
    <div className={styles.membersContainer}>
      <div className={styles.membersHeader}>
        <Text variant="large">Members</Text>

        <DSOrganizationInviteMember
          onInvite={(invite: OrganizationInvite) => {
            refetchInvites();
          }}
        />
      </div>
      <DSOrganizationMembersTable />
      {inviteData && inviteData.data.length > 0 && (
        <DSOrganizationInvitesTable />
      )}
    </div>
  );
};

export default DSOrganizationMembers;
