import { mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const MainContainerStyles = (isMobile: boolean) =>
  mergeStyles({
    padding: isMobile ? '1rem' : '1rem 1.5rem',
    backgroundColor: DEALROOMS_COLORS.white,
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.02)',
    borderRadius: '.25rem',
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,

    '.ag-root-wrapper': {
      border: 'none !important', // Remove the grid container border
    },

    '.ag-cell': {
      // border: 'none !important', // Remove grid lines between cells
      display: 'flex',
      alignItems: 'center',
      fontSize: '15px',
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      'div:has(span):not(:has(button))': {
        width: 'auto',
        maxWidth: '100%',
        span: {
          textOverflow: 'ellipsis',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        },
      },
    },

    '.ag-row': {
      color: DEALROOMS_COLORS.themePrimary,
      cursor: 'pointer',
    },

    '.ag-row-hover': {
      backgroundColor: DEALROOMS_COLORS.financialLighterGray,
    },
  });

export const detailsHeader = mergeStyles({
  fontSize: '0.8125rem !important',
  lineHeight: '1rem !important',
  fontWeight: '400 !important',
  minHeight: '1rem',
  height: '1rem',
  backgroundColor: DEALROOMS_COLORS.white,
  color: DEALROOMS_COLORS.themeSecondary,
});
