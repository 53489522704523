import { useNavigate } from 'react-router';
import { DSButton, DSSellerContactButton } from '../DS';
import { Share } from '@mui/icons-material';
import DSCurrentUserAvatarMenu from '../DS/DSCurrentUserAvatarMenu';
import { StyledNavbar } from './DealRoomBuyersNavbar.styles';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { CircularProgress } from '@mui/material';

const ROUTES = {
  SHARE: (orgSlug: string, dealRoomId: number) =>
    `/organization/${orgSlug}/decisionsite/${dealRoomId}/settings?group=invite`,
} as const;

export const DealRoomBuyersNavbar = () => {
  const navigate = useNavigate();
  const organizationSlug = useOrganizationSlug();
  const { dealRoomId, dealRoom, isLoading } = useDealRoom();

  const handleShare = () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }
    try {
      navigate(ROUTES.SHARE(organizationSlug, dealRoomId));
    } catch (error) {
      console.error('Navigation failed:', error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <StyledNavbar>
      <div
        className="navbar-title"
        aria-label={`Current Decision Site: ${dealRoom?.name}`}
      >
        {dealRoom?.name}
      </div>
      <div className="navbar-actions">
        <div className="action-buttons">
          <DSButton
            className="action-button"
            onClick={handleShare}
            startIcon={<Share />}
            branded
          >
            <span className="button-label">Share</span>
          </DSButton>

          <DSSellerContactButton branded />
        </div>
        <DSCurrentUserAvatarMenu branded />
      </div>
    </StyledNavbar>
  );
};
