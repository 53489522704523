import { useCallback, useState } from 'react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomFileArtifact } from '@meetingflow/common/Api/data-contracts';
import { IconButton, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const DSDashboardCloneButton = ({
    slug,
    dealRoomId,
    refetchDealRoomsData,
}: {
    slug: string;
    dealRoomId: number;
    refetchDealRoomsData: () => void;
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [isLoading, setIsLoading] = useState(false);

    /**
     * Creates a complete copy of a Decision Site including all its components:
     * - Basic properties (name, description, colors, logos)
     * - All artifacts (links, files, images, etc.)
     * - Callout cards (preserving references to artifacts)
     * - Key facts
     * 
     * The process follows these steps:
     * 1. Fetch the original Decision Site data
     * 2. Create a new Decision Site with modified name ("Copy of...")
     * 3. Clone all artifacts and maintain an ID mapping
     * 4. Clone callout cards using the new artifact IDs
     * 5. Clone key facts
     * 6. Refresh the UI to show the new Decision Site
     * 
     * @param dealRoomId - The ID of the Decision Site to clone
     */
    const cloneDecisionSite = useCallback(async (dealRoomId: number) => {
        try {
            const token = await getAccessTokenSilently();

            // Fetch the original Decision Site data
            setIsLoading(true);
            const originalDecisionSite = await DealRoomsApiClient.getDealRoom(
                slug,
                dealRoomId,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            ).then((response) => {
                return response.data;
            });

            // Create a new Decision Site with modified name ("Copy of...")
            const newDecisionSite = await DealRoomsApiClient.createDealRoom(slug, {
                name: "Copy of " + originalDecisionSite.name,
                description: originalDecisionSite.description || undefined,
                primaryColor: originalDecisionSite.primaryColor || undefined,
                sellerLogoUrl: originalDecisionSite.sellerLogoUrl || undefined,
                buyerLogoUrl: originalDecisionSite.buyerLogoUrl || undefined,
                hasMutualPlan: originalDecisionSite.hasMutualPlan,
                contacts: [],
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Clone all artifacts and maintain an ID mapping
            const artifactIDMap: Map<number, number> = new Map();

            // Process all artifacts first
            await Promise.all(originalDecisionSite.artifacts.map(async (artifact) => {
                switch (artifact.type) {
                    case 'LINK':
                        // eslint-disable-next-line no-case-declarations
                        const newLinkArtifact = await DealRoomsApiClient.createLinkArtifact(
                            slug,
                            newDecisionSite.data.id,
                            {
                                type: 'LINK',
                                url: artifact.url,
                                name: artifact.name,
                                description: artifact.description || undefined,
                                featuredAt: artifact.featuredAt || undefined
                            },
                            {
                                headers: { Authorization: `Bearer ${token}` },
                            }
                        );
                        artifactIDMap.set(artifact.id, newLinkArtifact.data.id);
                        break;
                    case 'FILE':
                    case 'IMAGE':
                    case 'VIDEO':
                    case 'AUDIO':
                    case 'DOCUMENT':
                        /* eslint-disable no-case-declarations */
                        const fileArtifact = artifact as DealRoomFileArtifact;
                        const response = await fetch(fileArtifact.fileUrl);
                        const blob = await response.blob();
                        const file = new File([blob], fileArtifact.fileName, {
                            type: fileArtifact.mimeType ?? undefined
                        });

                        const newFileArtifact = await DealRoomsApiClient.uploadFileArtifact(
                            slug,
                            newDecisionSite.data.id,
                            {
                                file: file,
                                ...(artifact.name ? { name: artifact.name } : {}),
                                ...(artifact.description ? { description: artifact.description } : {}),
                                ...(artifact.featuredAt ? { featuredAt: artifact.featuredAt } : {}),
                            },
                            {
                                headers: { Authorization: `Bearer ${token}` },
                            },
                        );
                        /* eslint-enable no-case-declarations */
                        artifactIDMap.set(artifact.id, newFileArtifact.data.id);
                        break;
                }
            }));

            // Clone callout cards using the new artifact IDs
            await Promise.all(originalDecisionSite.calloutCards?.map(async (calloutCard) => {
                await DealRoomsApiClient.createCalloutCard(
                    slug,
                    newDecisionSite.data.id,
                    {
                        title: calloutCard.title,
                        description: calloutCard.description,
                        link: calloutCard.link ?? null,
                        artifactId: artifactIDMap.get(calloutCard.artifactId ?? -1) ?? null,
                        ctaLabel: calloutCard.ctaLabel
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
            }) ?? []);

            // Clone key facts
            await Promise.all(originalDecisionSite.keyFacts?.map(async (keyFact) => {
                await DealRoomsApiClient.createKeyFact(
                    slug,
                    newDecisionSite.data.id,
                    {
                        title: keyFact.title,
                        value: keyFact.value || "",
                        editableByBuyer: keyFact.editableByBuyer,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
            }) ?? []);

            // Clone milestones 
            await Promise.all(originalDecisionSite.milestones?.map(async (milestone) => {
                await DealRoomsApiClient.saveMilestones(
                    slug,
                    newDecisionSite.data.id,
                    [
                        {
                            type: milestone.type,
                            dueDate: milestone.dueDate || null,
                            visible: milestone.visible,
                        },
                    ],
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    },
                )
            }) ?? []);

            // Refresh the UI to show the new Decision Site
            refetchDealRoomsData();
        } catch (err) {
            // Error handled by toast
        } finally {
            setIsLoading(false);
        }
    }, [getAccessTokenSilently, slug, refetchDealRoomsData]);

    return (
        <IconButton
            size="small"
            onClickCapture={async (e) => {
                e.stopPropagation();
                await cloneDecisionSite(dealRoomId);
            }}
            disabled={isLoading}
        >
            {isLoading ? (
                <CircularProgress size={20} />
            ) : (
                <ContentCopyIcon fontSize="small" />
            )}
        </IconButton>
    );
};