import { styles } from '../styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  MembersApiClient,
  DealRoomsApiClient,
} from '../../../../Services/NetworkCommon';
import {
  OrganizationMembersQuery,
  OrganizationDealRoomsQuery,
} from '../../../../QueryNames';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { DecisionSiteTableList } from '../Components/DecisionSiteTableList';
import {
  DealRoom,
  OrganizationMember,
} from '@meetingflow/common/Api/data-contracts';
import { ICellRendererParams } from 'ag-grid-community';
import useDeviceType from '../../../../Hooks/deviceDetection';
import { AsyncIconButton } from '../../../HOC/AsyncButton';
import toast from 'react-hot-toast';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../Hooks/useProfile';

export const DSOrganizationMembersTable = () => {
  const { slug: organizationSlug, role } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const { isMobile } = useDeviceType();
  const { user } = useUserProfile();
  const client = useQueryClient();

  // Fetch deal rooms data for the organization
  const { data: dealRoomsData } = useQuery(
    OrganizationDealRoomsQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRooms(
        {
          organizationSlug: organizationSlug!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const { data: membersData } = useQuery(
    OrganizationMembersQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.listMembers(
        {
          organizationSlug: organizationSlug!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug,
    },
  );

  const deleteMember = useCallback(
    async (
      memberId: number,
      memberName: string | undefined,
      memberEmail: string,
    ) => {
      const token = await getAccessTokenSilently();
      await toast.promise(
        MembersApiClient.deleteMember(organizationSlug!, memberId, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        {
          loading: 'Removing user',
          success: (result) => {
            if (result.status === 204) {
              client.invalidateQueries(
                OrganizationMembersQuery(organizationSlug!),
              );
              return `${memberName || memberEmail} has been removed from your workspace.`;
            }
            return `Unable to remove ${memberName || memberEmail} from your workspace.`;
          },
          error: () => {
            return `Unable to remove ${memberName || memberEmail} from your workspace.`;
          },
        },
      );
    },
    [getAccessTokenSilently, organizationSlug, client],
  );

  const columnDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: isMobile ? 170 : 200,
        flex: 1,
        suppressSizeToFit: false,
        unSortIcon: true,
        cellRenderer: (props: ICellRendererParams) => {
          if (!props || !('value' in props)) {
            return '';
          }
          return (
            <div>
              <span>{props.value}</span>
            </div>
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        suppressSizeToFit: true,
        unSortIcon: true,
        minWidth: isMobile ? 170 : 200,
      },
      ...(!isMobile
        ? [
            {
              field: 'role',
              headerName: 'Role',
              suppressSizeToFit: true,
              unSortIcon: true,
            },
            {
              headerName: '# of Decision Sites',
              suppressSizeToFit: true,
              unSortIcon: true,
              comparator: (valueA: number, valueB: number) => {
                return valueA - valueB;
              },
              valueGetter: (params: ICellRendererParams) => {
                const dealRooms = dealRoomsData?.data ?? [];
                return dealRooms.filter(
                  (dealRoom: DealRoom) => dealRoom.owner?.id === params.data.id,
                ).length;
              },
            },
            {
              field: 'delete',
              headerName: 'Delete',
              suppressSizeToFit: true,
              cellRenderer: (params: ICellRendererParams) => {
                const isAdmin = role === 'ADMIN';
                if (!isAdmin || params.data.id === user?.id) {
                  return null;
                }
                return (
                  <AsyncIconButton
                    iconProps={{ iconName: 'Delete' }}
                    onClick={async (e) => {}}
                    onClickCapture={async (e) => {
                      e.stopPropagation();
                      await deleteMember(
                        params.data.id,
                        params.data.name,
                        params.data.email,
                      );
                    }}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [isMobile, deleteMember, role, dealRoomsData?.data, user],
  );

  const membersTableRows = useMemo(() => {
    return (membersData?.data ?? [])
      .filter((member: OrganizationMember) => member.role !== 'GUEST')
      .map((member: OrganizationMember) => ({
        id: member.id,
        name: member.name,
        email: member.email,
        role: member.role,
      }));
  }, [membersData]);

  return (
    <DecisionSiteTableList items={membersTableRows} columns={columnDefs} />
  );
};
