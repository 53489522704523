import { mergeStyles, mergeStyleSets, Stack } from '@fluentui/react';
import { DecisionSiteEntryNavbar } from './DecisionSiteEntryNavbar';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DecisionSiteEntryContent } from './DecisionSiteEntryContent';
import useDeviceType from '../../../Hooks/deviceDetection';

export const DecisionSiteEntry = () => {
  const { isMobile } = useDeviceType();

  const styles = mergeStyleSets({
    mainContainer: {
      width: '100%',
      height: '100vh',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'auto',
      color: 'white',
      backgroundColor: DEALROOMS_COLORS.neutralLighter,
      padding: isMobile ? '2rem 1rem' : '3rem 2rem',
    },
  });

  return (
    <Stack className={styles.mainContainer}>
      <DecisionSiteEntryNavbar />
      <div className={styles.content}>
        <DecisionSiteEntryContent />
      </div>
    </Stack>
  );
};
