import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DraggableFileUploaderContainer } from '../../DraggableFileUploaderContainer';
import { useFileUpload } from '../UploadAttachmentComponents/hooks';
import { UploadIllustration } from './Illustration';
import toast from 'react-hot-toast';

interface StepProps {
  organizationSlug: string;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  primaryColor: string;
  setPrimaryColor: (color: string) => void;
}

// Styled components
const DropZone = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '240px',
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  cursor: 'pointer',
  transition: 'border-color 0.2s ease-in-out',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  }
}));

const ColorPickerButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .color-preview': {
    width: 24,
    height: 24,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  }
}));

export const BrandingDecisionSite = ({
  organizationSlug,
  selectedFile,
  setSelectedFile,
  primaryColor,
  setPrimaryColor,
}: StepProps) => {
  const PrimaryColorRef = React.useRef<HTMLInputElement>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState<string>('');

  const validateFile = useCallback(
    (file: File | null) => {
      if (!file) {
        toast.error('Please upload a valid image file.');
        return;
      }
      const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      if (allowedTypes.includes(file.type)) {
        if (file.size > 2 * 1024 * 1024) {
          toast.error('Please upload an image file less than 2MB.');
          return;
        }
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('Please upload an image file with a valid extension.');
      }
    },
    [setSelectedFile],
  );

  useEffect(() => {
    if (!selectedFile) return;
    validateFile(selectedFile);
  }, [validateFile, selectedFile]);

  const { handleSelectFile, handleDrop } = useFileUpload({
    setSelectedFile: validateFile,
  });

  return (
    <Stack spacing={3}>
      <Typography variant="h6">Branding</Typography>
      
      <Box>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Logo<Box component="span" color="error.main">*</Box>
        </Typography>
        
        <DraggableFileUploaderContainer handleOnDrop={handleDrop}>
            {!selectedFile && <UploadIllustration />}
            {selectedFile && (
              <Box
                component="img"
                src={filePreviewUrl}
                sx={{
                  width: 72,
                  height: 72,
                  borderRadius: '10%',
                  objectFit: 'cover'
                }}
              />
            )}
            <Typography variant="body1">Drop your file here</Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              Or{' '}
              <Button
                variant="text"
                onClick={handleSelectFile}
                sx={{
                  p: 0,
                  minWidth: 'auto',
                  textTransform: 'none',
                  verticalAlign: 'baseline',
                  fontWeight: 'regular',
                  '&:hover': {
                    background: 'none',
                    textDecoration: 'underline'
                  }
                }}
              >
                click here
              </Button>{' '}
              to upload manually.
              <br />
              Accepted formats: jpg, jpeg, png.
              <br />
              File size limit: 2MB
            </Typography>
            {selectedFile && (
              <Typography variant="body2" color="text.secondary">
                Selected file: {selectedFile.name}
              </Typography>
            )}
        </DraggableFileUploaderContainer>
      </Box>

      <Box>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Primary Color<Box component="span" color="error.main">*</Box>
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                width: 32,
                height: 32,
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider',
                bgcolor: primaryColor,
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8
                }
              }}
              onClick={() => PrimaryColorRef.current?.click()}
            />
            <input
              ref={PrimaryColorRef}
              type="color"
              value={primaryColor}
              onChange={(e) => setPrimaryColor(e.target.value)}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: 0,
                cursor: 'pointer'
              }}
            />
          </Box>
          <TextField
            size="small"
            value={primaryColor.toUpperCase()}
            onChange={(e) => {
              const value = e.target.value;
              if (/^#[0-9A-Fa-f]{0,6}$/.test(value)) {
                setPrimaryColor(value.toLowerCase());
              }
            }}
            inputProps={{
              maxLength: 7,
              style: { fontFamily: 'monospace', }
            }}
            sx={{ width: 100 }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};
