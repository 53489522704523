import { styled } from '@mui/material/styles';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../Themes/Themes';

export const ContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  overflow: 'auto',
  width: '100%',
}));

export const SectionTitle = styled(Typography)(() => ({
  color: DEALROOMS_COLORS.themePrimary,
  fontWeight: 400,
}));

export const WorkspacesContainer = styled(Grid)(() => ({
  flexWrap: 'wrap',
  gap: '2rem',
  padding: '1rem',
}));

export const WorkspacePaper = styled(Paper)(() => ({
  minWidth: 150,
  padding: '1rem',
  margin: 0,
  borderRadius: 4,
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  boxShadow: '0 2px 2px 0 #00000005',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: DEALROOMS_COLORS.neutralLighter,
  },
}));
