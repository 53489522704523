import { Stack, Text } from '@fluentui/react';
import { styles } from '../styles';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { DSTabs, DSTab } from '../../DS';
import { DSOrganizationConfiguration } from './DSOrganizationConfiguration/DSOrganizationConfiguration';
import { DSOrganizationMembers } from './DSOrganizationMembers/DSOrganizationMembers';
import { DSOrganizationBilling } from './DSOrganizationBilling/DSOrganizationBilling';
import { Box } from '@mui/material';
import {
  WorkspacePremium as WorkspaceIcon,
  People as PeopleIcon,
  Security as SecurityIcon,
  CreditCard as CreditCardIcon,
} from '@mui/icons-material';

const DSOrganizationSettings = () => {
  const { name } = useOrganization();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Stack className={styles.headerTitleContainer}>
          <Text className={styles.title}>Organization Settings</Text>
          <Text className={styles.subtitle}>{name}</Text>
        </Stack>
      </div>

      <div className={styles.content}>
        <DSTabs defaultValue="workspace" className={styles.tabsWrapper}>
          <DSTab
            value="workspace"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <WorkspaceIcon fontSize="small" />
                Workspace
              </Box>
            }
            panel={<DSOrganizationConfiguration />}
          />
          <DSTab
            value="members"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PeopleIcon fontSize="small" />
                Members
              </Box>
            }
            panel={<DSOrganizationMembers />}
          />

          {/* <DSTab
            value="billing"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CreditCardIcon fontSize="small" />
                Billing
              </Box>
            }
            panel={<DSOrganizationBilling />}
          /> */}
          {/* <DSTab
            value="security"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <SecurityIcon fontSize="small" />
                Security
              </Box>
            }
            panel={<DSOrganizationMembers />}
          /> */}
        </DSTabs>
      </div>
    </div>
  );
};

export default DSOrganizationSettings;
