import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

export const getDSOrganizationConfigurationStyles = (isMobile?: boolean) => {
  return mergeStyleSets({
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      padding: '1.5rem',
      backgroundColor: DEALROOMS_COLORS.white,
      borderRadius: '.25rem',
      boxShadow: '0px 2px 2px 0px #00000005',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    sectionTitle: {
      color: DEALROOMS_COLORS.themePrimary,
    },
    sectionDescription: {
      color: DEALROOMS_COLORS.themeSecondary,
      fontSize: '0.875rem',
      fontWeight: '400',
      paddingBottom: '0.5rem',
    },
  });
};
