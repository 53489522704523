import { Stack, Text } from '@fluentui/react';
import { styles } from '../styles';
import { DSTabs, DSTab } from '../../DS';
import { Box } from '@mui/material';
import {
    Person as PersonIcon,
    Settings as SettingsIcon,
    Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { DSProfileAccount } from './DSProfileAccount/DSProfileAccount';

// These will be implemented later as separate components
const DSPreferences = () => <div>Preferences Content</div>;
const DSNotifications = () => <div>Notifications Content</div>;

export const DSProfile = () => {
    const { slug, role, name } = useOrganization();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Stack className={styles.headerTitleContainer}>
                    <Text className={styles.title}>Personal Settings</Text>
                    <Text className={styles.subtitle}>{name}</Text>
                </Stack>
            </div>

            <div className={styles.content}>
                <DSTabs defaultValue="personal" className={styles.tabsWrapper}>
                    <DSTab
                        value="personal"
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PersonIcon fontSize="small" />
                                My Account
                            </Box>
                        }
                        panel={<DSProfileAccount />}
                    />
                    {/* <DSTab
                        value="preferences"
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <SettingsIcon fontSize="small" />
                                My Preferences
                            </Box>
                        }
                        panel={<DSPreferences />}
                    />
                    <DSTab
                        value="notifications"
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <NotificationsIcon fontSize="small" />
                                My Notifications
                            </Box>
                        }
                        panel={<DSNotifications />}
                    /> */}
                </DSTabs>
            </div>
        </div>
    );
};