import { Text, Stack } from '@fluentui/react';
import { TextField } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { OrganizationQuery, OrganizationsQuery } from '../../../../../QueryNames';
import { OrganizationsApiClient } from '../../../../../Services/NetworkCommon';
import { DSOrganizationLogo } from './DSOrganizationLogo';
import { getDSOrganizationConfigurationStyles } from './DSOrganizationConfiguration.styles';

export const DSOrganizationConfiguration = () => {
  const { isMobile } = useDeviceType();
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const styles = getDSOrganizationConfigurationStyles(isMobile);
  const { organization, slug: orgSlug, refetch } = useOrganization();

  const handleNameChange = async (newName: string) => {
    try {
      const token = await getAccessTokenSilently();
      const result = await OrganizationsApiClient.patchOrganization(
        orgSlug!,
        { name: newName },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (result.status === 200) {
        client.setQueryData(OrganizationQuery(orgSlug!), result);
        client.invalidateQueries(OrganizationsQuery);
        await refetch();
        toast.success('Organization name updated successfully');
      }
    } catch (error) {
      toast.error('Failed to update organization name');
      console.error('Error updating organization name:', error);
    }
  };

  return (
    <Stack className={styles.content}>
      <Stack className={styles.section}>
        <Text className={styles.sectionTitle} variant="large">Organization Name</Text>
        <Text className={styles.sectionDescription} variant="medium">Update the name of your organization.</Text>

        <TextField
          defaultValue={organization?.name}
          placeholder="Enter organization name"
          onBlur={(e) => {
            const newName = e.target.value;
            if (newName && newName !== organization?.name) {
              handleNameChange(newName);
            }
          }}
        />
      </Stack>

      <Stack className={styles.section}>
        <Text className={styles.sectionTitle} variant="large">Organization Logo</Text>
        <Text className={styles.sectionDescription} variant="medium">Recommended size is 128px by 128px.</Text>

        <DSOrganizationLogo />
      </Stack>
    </Stack>
  );
};

export default DSOrganizationConfiguration;
