import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { getCreateSiteStyles } from './styles';
import { validateDomain } from '../../../../Helpers/URLHelpers';

interface CreateDecisionSiteProps {
  siteName: string;
  setSiteName: (name: string) => void;
  companyName: string;
  setCompanyName: (name: string) => void;
}

export const CreateDecisionSite: React.FC<CreateDecisionSiteProps> = ({
  siteName,
  setSiteName,
  companyName,
  setCompanyName,
}) => {
  const styles = getCreateSiteStyles();
  const [domainError, setDomainError] = useState<string>('');

  const handleDomainChange = (newValue: string = '') => {
    setCompanyName(newValue);
    const { message } = validateDomain(newValue);
    setDomainError(message);
  };

  return (
    <div className={styles.content}>
      <TextField
        fullWidth
        required
        variant="outlined"
        label="Decision Site Name"
        placeholder="Enter the Decision Site Name"
        value={siteName}
        onChange={(e) => setSiteName(e.target.value)}
      />

      <TextField
        fullWidth
        required
        variant="outlined"
        label="Company Domain"
        placeholder="Enter the other party's domain (e.g., augment.co)"
        value={companyName}
        onChange={(e) => handleDomainChange(e.target.value)}
        error={!!domainError}
        helperText={domainError}
        onBlur={(e) => {
          // Validate on focus out
          handleDomainChange(e.target.value);
        }}
      />
    </div>
  );
};
