import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

export const getDSOrganizationLogoStyles = (isMobile?: boolean) => {
  return mergeStyleSets({
    logoWrapper: {
      position: 'relative',
      width: '48px',
      height: '48px',
      cursor: 'pointer',
      borderRadius: '8px',
      overflow: 'hidden',
      ':hover': {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    placeholderLogo: {
      width: '100%',
      height: '100%',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    placeholderIcon: {
      fontSize: 24,
      color: DEALROOMS_COLORS.neutralDark,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.2s ease-in-out',
    },
    editIcon: {
      fontSize: 18,
      color: DEALROOMS_COLORS.white,
    },
  });
};
