import { useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { DealRoomsApiClient } from "../../../../Services/NetworkCommon";
import { DeferredPromise } from "../../../../Helpers/DeferredPromise";
import { useAuth0 } from "@auth0/auth0-react";
import { IconButton, CircularProgress } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

interface DSDashboardDeleteButtonProps {
    dealRoomId: number;
    slug: string;
    refetchDealRoomsData: () => void;
    createConfirmDeleteDeferred: () => DeferredPromise<boolean>;
}

export const DSDashboardDeleteButton = ({
    dealRoomId,
    slug,
    refetchDealRoomsData,
    createConfirmDeleteDeferred,
}: DSDashboardDeleteButtonProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);

    const deleteDecisionSite = useCallback(async (dealRoomId: number) => {
        try {
            setIsLoading(true);
            const confirmDelete = await createConfirmDeleteDeferred().promise;
            if (!confirmDelete) {
                return;
            }

            const token = await getAccessTokenSilently();
            await toast.promise(
                DealRoomsApiClient.deleteDealRoom(slug, dealRoomId, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
                {
                    loading: 'Deleting Decision Site',
                    success: () => {
                        refetchDealRoomsData();
                        return 'Successfully deleted Decision Site';
                    },
                    error: () => 'Something went wrong deleting Decision Site, please try again later',
                },
            );
        } catch (err) {
            // Error handled by toast
        } finally {
            setIsLoading(false);
        }
    }, [createConfirmDeleteDeferred, getAccessTokenSilently, refetchDealRoomsData, slug]);

    return (
        <IconButton
            onClickCapture={async (e) => {
                e.stopPropagation();
                await deleteDecisionSite(dealRoomId);
            }}
            disabled={isLoading}
            color="error"
        >
            {isLoading ? (
                <CircularProgress size={20} color="error"/>
            ) : (
                <DeleteOutlinedIcon color="error" />
            )}
        </IconButton>
    );
};