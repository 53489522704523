import { useCallback, useMemo, useState } from 'react';
import { IconButton, Modal, Spinner } from '@fluentui/react';
import {
  Company,
  CreateDealRoomPayload,
} from '@meetingflow/common/Api/data-contracts';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { ChromeCloseIconProps } from '../../../../utils/iconProps';
import { useNavigate } from '../../../../Hooks/useNavigate';
import {
  DealRoomAsyncPrimaryButton,
  DealRoomCommandBarButton,
} from '../../Components/DealRoomButton';
import { getStyles } from './styles';
import { StepCounter } from './StepCounter';
import { CreateDecisionSite } from './CreateDecisionSite';
import {
  ApiClient,
  DealRoomsApiClient,
} from '../../../../Services/NetworkCommon';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { BrandingDecisionSite } from './BrandingDecisionSite';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import toast from 'react-hot-toast';
import { BigPictureCompanyDetails } from '../../../../types/BigPictureCompanyDetails';
import { useTheme } from '@mui/material';
import { validateDomain } from '../../../../Helpers/URLHelpers';

const stepArray = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
];

interface NewDealRoomDialogProps {
  organizationSlug: string;
  onCreate: (payload: CreateDealRoomPayload) => void;
  onDismiss: () => void;
  refetchDealRoomsData: () => void;
}
/**
 * Dialog component for creating a new Decision Site (Deal Room)
 * Allows users to set a name and add contacts
 */
export const NewDealRoomDialog = ({
  organizationSlug,
  onCreate,
  onDismiss,
  refetchDealRoomsData,
}: NewDealRoomDialogProps) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useUserProfile();
  const { slug } = useOrganization();

  // Form state
  const [isLoading, setIsLoading] = useState(false);
  const [siteName, setSiteName] = useState('');
  const [companyDomain, setCompanyDomain] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [primaryColor, setPrimaryColor] = useState<string>(
    DEALROOMS_COLORS.themePrimary,
  );
  const { breakpoints } = useTheme();

  const styles = getStyles();

  const domainValidation = useCallback(
    () => validateDomain(companyDomain),
    [companyDomain],
  );

  const handleCreateNewDealRoom = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      const dealRoom = await DealRoomsApiClient.createDealRoom(
        slug!,
        {
          name: siteName,
          contacts: [],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      refetchDealRoomsData();
      return dealRoom;
    } catch (err) {
      return null;
    }
  }, [getAccessTokenSilently, refetchDealRoomsData, siteName, slug]);

  const handleNewDealRoomColor = useCallback(
    async (dealRoomId: number) => {
      if (!organizationSlug) return;
      try {
        const token = await getAccessTokenSilently();

        await DealRoomsApiClient.updateDealRoom(
          organizationSlug,
          dealRoomId,
          {
            primaryColor: primaryColor,
            // NOTE: secondaryColor is not used for now
            // secondaryColor: secondaryColor,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        refetchDealRoomsData();
      } catch (error) {
        console.error('Failed to update deal room color');
      }
    },
    [
      getAccessTokenSilently,
      organizationSlug,
      primaryColor,
      refetchDealRoomsData,
    ],
  );

  const handleNewDealRoomAttachment = useCallback(
    async (dealRoomId: number) => {
      if (!organizationSlug) return;

      const token = await getAccessTokenSilently();

      const response = await DealRoomsApiClient.uploadBuyersLogo(
        organizationSlug!,
        dealRoomId!,
        { file: selectedFile! },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status !== 200) {
        toast.error('Something went wrong uploading the logo');
        return;
      }

      refetchDealRoomsData();
    },
    [
      getAccessTokenSilently,
      organizationSlug,
      selectedFile,
      refetchDealRoomsData,
    ],
  );

  // Handlers
  const handleNext = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    let createdDealRoom = null;
    const domain = companyDomain.replace('https://', '').replace('http://', '');

    switch (currentStep) {
      case 1:
        if (!siteName) return;
        if (!companyDomain) return;
        if (!user) return;

        setIsLoading(true);

        try {
          const result = await ApiClient.get<BigPictureCompanyDetails>(
            `/company/${domain}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );

          if (!result?.data.logo) {
            break;
          }

          const logo = result.data.logo.replace('http://', 'https://');

          try {
            const response = await fetch(logo);
            const blob = await response.blob();
            const file = new File([blob], 'company_logo.png', {
              type: blob.type,
            });
            setSelectedFile(file);
          } catch (error) {
            console.error('Failed to fetch and convert logo:', error);
          }

          break;
        } catch (error) {
          console.error('Failed to fetch company details:', error);
          break;
        }

      case 2:
        if (!selectedFile) return;
        if (!primaryColor) return;
        setIsLoading(true);
        createdDealRoom = await handleCreateNewDealRoom();
        if (!createdDealRoom) {
          toast.error('Something went wrong creating Decision Site');
          setIsLoading(false);
          return;
        }
        await handleNewDealRoomColor(createdDealRoom.data.id);
        await handleNewDealRoomAttachment(createdDealRoom.data.id);
        setIsLoading(false);
        navigate(
          `/organization/${slug}/decisionsite/${createdDealRoom.data.id}`,
        );
        return;
      default:
        break;
    }

    setIsLoading(false);
    if (currentStep < stepArray.length) {
      setCurrentStep(currentStep + 1);
      return;
    }
  }, [
    getAccessTokenSilently,
    companyDomain,
    currentStep,
    handleCreateNewDealRoom,
    handleNewDealRoomAttachment,
    handleNewDealRoomColor,
    navigate,
    primaryColor,
    selectedFile,
    siteName,
    slug,
    user,
  ]);

  const handleBack = useCallback(() => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      onDismiss();
    }
  }, [currentStep, onDismiss]);

  const isNextButtonDisabled = useMemo(() => {
    if (isLoading) return true;
    switch (currentStep) {
      case 1: {
        const { isValid: isValidDomain } = domainValidation();
        return !siteName || !isValidDomain;
      }
      case 2:
        return !(selectedFile && primaryColor);
      default:
        return false;
    }
  }, [
    isLoading,
    currentStep,
    siteName,
    domainValidation,
    selectedFile,
    primaryColor,
  ]);

  const currentStepComponent = useMemo(() => {
    if (isLoading) return <Spinner />;

    switch (currentStep) {
      case 1:
        return (
          <CreateDecisionSite
            siteName={siteName}
            companyName={companyDomain}
            setSiteName={setSiteName}
            setCompanyName={setCompanyDomain}
          />
        );
      case 2:
        return (
          <BrandingDecisionSite
            organizationSlug={organizationSlug}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            primaryColor={primaryColor}
            setPrimaryColor={setPrimaryColor}
          />
        );
      default:
        return <></>;
    }
  }, [
    isLoading,
    currentStep,
    siteName,
    companyDomain,
    organizationSlug,
    selectedFile,
    primaryColor,
  ]);

  const backButtonLabel = useMemo(() => {
    if (currentStep > 1) return 'Back';
    return 'Cancel';
  }, [currentStep]);

  const forwardButtonLabel = useMemo(() => {
    if (currentStep < stepArray.length) return 'Continue';
    return breakpoints.down('sm') ? 'Create' : 'Create Decision Site';
  }, [currentStep, breakpoints]);

  return (
    <Modal className={styles.modalWrapper} isOpen onDismiss={onDismiss}>
      <div className={styles.header}>
        <span>Create a New Decision Site</span>
        <IconButton
          className={styles.closeIcon}
          ariaLabel="Close"
          iconProps={ChromeCloseIconProps}
          onClick={onDismiss}
          alt="Close image"
          disabled={isLoading}
        />
      </div>
      <StepCounter stepArray={stepArray} currentStep={currentStep} />
      {currentStepComponent}
      <div className={styles.buttons}>
        <DealRoomCommandBarButton
          buttonStyleType="COMMAND_BAR"
          onClick={handleBack}
          text={backButtonLabel}
          customClasses={styles.cancel}
        />
        <DealRoomAsyncPrimaryButton
          className={styles.confirm}
          onClick={handleNext}
          disabled={isNextButtonDisabled}
        >
          {forwardButtonLabel}
        </DealRoomAsyncPrimaryButton>
      </div>
    </Modal>
  );
};
